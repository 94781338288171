'use client';

import classNames from 'classnames';
import { FC, PropsWithChildren, memo } from 'react';
import { TableBody } from './table-body';
import { TableHeader } from './table-header';
import { TableProvider, useTableContext } from './table-provider';
import { TableProps } from './types';
function InnerTable<T>() {
  const {
    ref,
    mode,
    compact,
    className,
    headerClassName,
    headerRowClassName,
    headerCellClassName,
    subHeaderRowClassName,
    bodyClassName,
    rowClassName,
    cellClassName,
    columns,
    data,
    rowKey,
    rowFilter,
    emptyMessage,
    emptyRowClassName,
    stickyHeader,
    stickyLeftClassName,
    stickyTopClassName,
    expandable,
    expandableRow,
    expandableData,
    expandableDataRemoteOptions,
    expandableComponent,
    expandedRowClassName,
    onRowMouseEnter,
    onRowMouseLeave,
    onRowClick
  } = useTableContext<T>();
  return <table ref={ref} className={classNames(className, {
    'table-auto': mode === 'auto',
    'table-fixed': mode === 'fixed',
    'table-compact': compact
  })} data-sentry-component="InnerTable" data-sentry-source-file="table.tsx">
      <TableHeader columns={columns} compact={compact} expandable={expandable} headerClassName={headerClassName} headerRowClassName={headerRowClassName} headerCellClassName={headerCellClassName} subHeaderRowClassName={subHeaderRowClassName} stickyHeader={stickyHeader} stickyLeftClassName={stickyLeftClassName} stickyTopClassName={stickyTopClassName} data-sentry-element="TableHeader" data-sentry-source-file="table.tsx" />
      <TableBody className={bodyClassName} columns={columns} compact={compact} data={data} emptyMessage={emptyMessage} emptyRowClassName={emptyRowClassName} rowKey={rowKey} rowClassName={rowClassName} rowFilter={rowFilter} cellClassName={cellClassName} stickyLeftClassName={stickyLeftClassName} expandable={expandable} expandableRow={expandableRow} expandableData={expandableData} expandableDataRemoteOptions={expandableDataRemoteOptions} expandableComponent={expandableComponent} expandedRowClassName={expandedRowClassName} onRowMouseEnter={onRowMouseEnter} onRowMouseLeave={onRowMouseLeave} onRowClick={onRowClick} data-sentry-element="TableBody" data-sentry-source-file="table.tsx" />
    </table>;
}
function _Table<T extends Record<string, any>>({
  mode = 'auto',
  className = 'w-full',
  emptyMessage = 'No data available',
  stickyLeftClassName = 'left-0',
  stickyTopClassName = 'top-0',
  allowHorizontalScroll,
  ...restProps
}: TableProps<T>) {
  const tableContent = <InnerTable<T> />;
  return <TableProvider<T> allowHorizontalScroll={allowHorizontalScroll} mode={mode} className={className} emptyMessage={emptyMessage} stickyLeftClassName={stickyLeftClassName} stickyTopClassName={stickyTopClassName} {...restProps} data-sentry-element="TableProvider" data-sentry-component="_Table" data-sentry-source-file="table.tsx">
      {allowHorizontalScroll ? <TableViewport>{tableContent}</TableViewport> : tableContent}
    </TableProvider>;
}
const TableViewport: FC<PropsWithChildren> = ({
  children
}) => <div className="w-full h-full overflow-auto xl:overflow-unset print:overflow-hidden" data-sentry-component="TableViewport" data-sentry-source-file="table.tsx">
    {children}
  </div>;
export const Table = memo(_Table) as typeof _Table;