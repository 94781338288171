import { useLayoutEffect, useState } from 'react';

export function useDeepMemo<T>(value: T): T {
  const [current, setCurrent] = useState<T>(value);

  useLayoutEffect(() => {
    if (!current || JSON.stringify(value) !== JSON.stringify(current)) {
      setCurrent(value);
    }
  }, [value, current]);

  return current ?? value;
}
