import { memo } from 'react';
import { TableRowGroup, TableRowGroupProps } from './table-row-group';
import { TableProps } from './types';
type TableBodyProps<T> = TableRowGroupProps<T> & {
  className?: TableProps<T>['bodyClassName'];
};
function _TableBody<T>({
  className,
  ...rowGroupProps
}: TableBodyProps<T>) {
  return <tbody className={className} data-sentry-component="_TableBody" data-sentry-source-file="table-body.tsx">
      <TableRowGroup<T> {...rowGroupProps} data-sentry-element="TableRowGroup" data-sentry-source-file="table-body.tsx" />
    </tbody>;
}
export const TableBody = memo(_TableBody) as typeof _TableBody;