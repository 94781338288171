import { RefObject, useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export type Size = { width: number; height: number };

const DEFAULT_SIZE: Size = { width: 0, height: 0 };

// This function comes from the `@react-hook/resize-observer` docs.
export const useSize = (target: RefObject<HTMLElement>): Size => {
  const [size, setSize] = useState<Size>(DEFAULT_SIZE);
  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);
  useResizeObserver(target, (entry) => {
    setSize({ width: entry.contentRect.width, height: entry.contentRect.height });
  });
  return size;
};
