import classNames from 'classnames';
import { memo } from 'react';
import { TableHeaderCell } from './table-header-cell';
import { TableColumn, TableProps } from './types';
import { getHeaderCellClassName } from './util';
type TableHeaderProps<T> = Pick<TableProps<T>, 'columns' | 'compact' | 'stickyHeader' | 'stickyLeftClassName' | 'stickyTopClassName' | 'expandable' | 'headerCellClassName' | 'headerClassName' | 'headerRowClassName' | 'subHeaderRowClassName'>;
function _TableHeader<T>({
  headerClassName,
  headerRowClassName,
  headerCellClassName,
  subHeaderRowClassName,
  columns,
  compact,
  stickyHeader,
  stickyLeftClassName = 'left-0',
  stickyTopClassName = 'top-0',
  expandable
}: TableHeaderProps<T>) {
  const hasSubHeaders = columns.some(x => !!x.subHeader);
  return <thead className={classNames('relative', headerClassName)} data-sentry-component="_TableHeader" data-sentry-source-file="table-header.tsx">
      <tr className={classNames(headerRowClassName, {
      'sticky z-20': stickyHeader,
      [stickyTopClassName]: stickyHeader
    })}>
        {expandable && <th />}
        {filterColumnsByColSpan(columns.filter(x => !x.hidden), x => x.headerColSpan).map((column, columnIndex) => <TableHeaderCell<T> key={column.key as string ?? columnIndex} headerCellClassName={headerCellClassName} column={column} columnIndex={columnIndex} compact={compact} isFirstColumn={columnIndex === 0} isLastColumn={columnIndex === columns.length - 1} stickyLeftClassName={stickyLeftClassName} />)}
      </tr>
      {hasSubHeaders && <tr className={subHeaderRowClassName}>
          {expandable && <th />}
          {filterColumnsByColSpan(columns.filter(x => !x.hidden), x => x.subHeaderColSpan).map((column, columnIndex) => <td key={column.key as string ?? columnIndex} className={getHeaderCellClassName({
        column,
        columnIndex,
        compact,
        isFirstColumn: columnIndex === 0,
        isLastColumn: columnIndex === columns.length - 1,
        headerCellClassName,
        stickyLeftClassName
      }, 'subHeader')} colSpan={column.subHeaderColSpan}>
              {column.subHeader ?? ''}
            </td>)}
        </tr>}
    </thead>;
}
function filterColumnsByColSpan<T>(columns: TableColumn<T>[], colSpanAccessor: (column: TableColumn<T>) => number | undefined): TableColumn<T>[] {
  const filteredColumns: TableColumn<T>[] = [];
  let cellIndex = 0;
  let columnIndex = 0;
  for (const column of columns) {
    const colSpan = colSpanAccessor(column) || 1;
    if (cellIndex === columnIndex) {
      filteredColumns.push(column);
      cellIndex += colSpan;
    }
    columnIndex++;
  }
  return filteredColumns;
}
export const TableHeader = memo(_TableHeader) as typeof _TableHeader;