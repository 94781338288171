import EventEmitter from 'emittery';
import { useEffect } from 'react';

class RemoteOperationBroker extends EventEmitter {
  revalidate(key: string) {
    this.emit('revalidate', key);
  }
}

export const remoteOperationBroker = new RemoteOperationBroker();

export function useRemoteOperationWithKey(key: string | undefined, refetch: () => void) {
  useEffect(() => {
    if (key && refetch) {
      return remoteOperationBroker.on('revalidate', (revalidateKey: string) => {
        // TODO: support globs
        if (key === revalidateKey) {
          refetch();
        }
      });
    }
  }, [key, refetch]);
}
